// jwelleryCard.styles.scss

.jwellery-card {
  .web {
    cursor: pointer;
    width: calc(((100vw * 9 / 12) / 4) - 50px);
    min-height: 380px;
    margin-right: 20px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(60, 60, 60, 0.4);
    border-radius: 16px;
    overflow: hidden;
    font-family: "Work Sans", sans-serif;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 8px 28px rgba(93, 62, 188, 0.2);
    }

    .card-image-container {
      background: #f8f8f8;
      text-align: center;
      //padding: px;
      flex-grow: 1;

      .card-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    .card-content {
      padding: 16px;
      text-align: center;
      background-color: #fff;

      .card-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 1.2em;
        height: 2.1em;
        color: #333;
      }

      .card-price {
        color: #a36e29;
        font-weight: bold;
        font-size: 18px; // Adjusted font size for better visibility
        margin-bottom: 16px;
      }

      .add-to-cart-button {
        width: 100%;
        background-color: #a36e29;
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        padding: 8px 0;
        margin-bottom: 8px;
      }

      .check-delivery {
        font-size: 12px;
        color: #333;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 5px;
      }
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 900px) {
    .web {
      display: none;
    }

    .mobile {
      box-sizing: border-box;
      cursor: pointer;
      width: auto;
      background: #ffffff;
      box-shadow: 0px 4px 24px rgba(93, 62, 188, 0.1);
      border-radius: 16px;
      overflow: hidden;
      font-family: "Work Sans", sans-serif;
      display: flex;
      flex-direction: column;
      transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0px 8px 28px rgba(93, 62, 188, 0.2);
      }

      .card-image-container {
        background: #f8f8f8;
        text-align: center;
        //padding: px;
        flex-grow: 1;

        .card-image {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }

      .card-content {
        padding: 16px;
        text-align: center;
      }
    }
  }
}
