.product-page {
  overflow-x: hidden;

  .web {
    .block-with-background {
      background-color: #f0f0f0;
      padding: 20px;
      text-align: center;

      .page-heading {
        margin-left: 5vw;
        margin-top: 20px;
        text-align: start;
        font-weight: bold;
        color: #a36e29;
      }

      .breadcrumbs-container {
        margin-left: 5vw;
        background-color: #f0f0f0;
        padding: 10px 0;
      }

      .breadcrumb-link {
        text-decoration: none;
        color: black;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .product-container {
      width: 90vw;
      margin: 0 auto;

      .filter-title {
        height: max-content;
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading {
          text-align: center;
          color: #a36e29;
          font-size: 1.2rem;
          font-weight: 600;
          font-family: "Roboto", sans-serif;
          margin-left: 20px;
          margin-top: 18px;
          margin-bottom: 18px;
        }

        .clear {
          color: #a36e29;
          font-family: "Roboto", sans-serif;
          font-size: 1rem;
          margin-right: 20px;
          font-weight: bold;
          cursor: pointer;
        }
      }

      .products {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        margin-bottom: 50px;

        .heading {
          margin-left: 30px;
          margin-top: 20px;
          text-align: center;
          color: #a36e29;
          font-size: 1.4rem;
          font-weight: 600;
          margin-bottom: 30px;
        }

        .product-card {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          padding: 10px 30px;
        }
      }
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 900px) {
    .web {
      display: none;
    }

    .mobile {
      display: block;

      .block-with-background {
        background-color: #f0f0f0;
        padding: 5px;
        text-align: center;
        margin-top: 20vh;
        height: 12vh;

        .breadcrumbs-container {
          margin-left: 5vw;
          background-color: #f0f0f0;
          padding: 10px 0;
        }

        .breadcrumb-link {
          font-size: small;
          text-decoration: none;
          color: black;
          font-family: "Roboto", sans-serif;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .product-container {
        width: 100%;
        max-width: 100%;
        // height: calc(80vh - 55px);
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 10px;

        .product-card {
          display: flex;
          flex-direction: row;
          justify-content: center; // Align items to the start for better spacing
          align-items: center;
          flex-wrap: wrap;
          padding: 10px; // Adjust padding to be consistent all around
          gap: 20px;
        }
      }
    }
  }
}
