.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .selected-item {
    position: relative;
    width: 100%;
    margin: 16px 0;

    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }

    video {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }

  .item-thumbnails {
    display: flex;
    justify-content: center;
    gap: 10px; // space between thumbnails

    img {
      width: 5rem;
      height: 5rem;
      cursor: pointer;
      transition: opacity 0.3s ease, border 0.3s ease;
      border-radius: 5px;

      &.selected {
        opacity: 1;
        border: 2px solid #a36e29;
        border-radius: 5px;
      }
    }

    .thumbnail {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5rem;
      height: 5rem;
      cursor: pointer;
      background-color: #eee;
      transition: background-color 0.3s ease;
      border-radius: 5px;

      &.selected {
        background-color: #ccc;
        border: 2px solid #a36e29;
        border-radius: 5px;
      }
    }
  }

  // Navigation arrows styling
  .MuiIconButton-root {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    z-index: 10;
    &:hover {
      background-color: transparent;
    }

    &.prev {
      left: 10px;
    }

    &.next {
      right: 10px;
    }
  }
}

// Media query for responsiveness
@media (max-width: 768px) {
  .carousel-container {
    .selected-item {
      width: 90%; // smaller width on mobile devices
    }

    .item-thumbnails {
      img {
        width: 50px; // smaller thumbnails on mobile devices
        height: auto;
      }

      .thumbnail {
        width: 50px; // match the image thumbnail size on mobile devices
        height: 50px; // match the image thumbnail size on mobile devices
      }
    }

    .MuiIconButton-root {
      &.prev {
        left: 10px; // closer to the edge on mobile
      }

      &.next {
        right: 10px; // closer to the edge on mobile
      }
    }
  }
}
