// priceFilter.styles.scss

.price-filter {
  padding: 20px;
  width: 70%;
  .filter-heading {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
  }

  .price-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;
      position: relative;

      .count {
        font-size: 0.8rem;
        color: #757575;
        margin-left: auto;
      }
    }
  }

  .show-more {
    margin-top: 15px;
    cursor: pointer;
    color: #a36e29;
    font-size: 0.9rem;
    text-decoration: underline;
    text-align: left;

    &:hover {
      text-decoration: none;
    }
  }
}
