.navbar {
  margin-bottom: 7%;

  .web {
    .appbar {
      height: 8.5rem;
      background-color: #fff;

      .rates {
        height: 2.5rem;
        width: 100%;
        background: linear-gradient(to left, #a36e29, #c18a3d);
      }

      .toolbar {
        position: relative;
        margin: auto;
        width: 90%;
        height: 6rem;

        display: flex;
        align-items: center;

        .logo {
          height: 120px;
          margin-right: 0.5rem;
          vertical-align: middle;
          cursor: pointer;
        }

        .sub-logo {
          height: 60px;
          margin-right: 0.5rem;
          vertical-align: middle;
          cursor: pointer;
        }

        .menu-items {
          margin-left: 20px;
          display: flex;
          align-items: center;

          .menu-item {
            height: 100px;
            position: relative;
            color: #000000;
            text-decoration: none;
            margin-right: 20px;
            transition: color 0.3s;

            .menu-item-btn {
              font-family: "Roboto", sans-serif;
              color: #a36e29;
              font-weight: 600;
              font-size: 0.8rem;
              height: 100%;

              &:hover {
                border-bottom: 2px solid #a36e29;
              }
            }

            &:hover {
              color: #ffc107;
              border-bottom: 2px solid #a36e29; /* or any other hover color you prefer */
            }

            .sub-categories {
              position: absolute;
              width: 50vw;
              left: 0;
              display: none;
              flex-direction: column;
              background-color: #fff; /* or any other background color you prefer */
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              padding: 20px;
              z-index: 1;
              column-count: 2;

              .sub-category-column {
                display: flex;
                flex-direction: column;
                margin-right: 20px;

                .sub-category {
                  color: #333;
                  text-decoration: none;
                  padding: 5px 0;
                  break-inside: avoid;
                  transition: color 0.3s;
                  font-family: "Roboto", sans-serif;
                  font-size: 0.6rem;

                  &:hover {
                    color: #a36e29; /* or any other hover color you prefer */
                  }
                }
              }
            }

            &:hover .sub-categories {
              display: flex;
            }
          }
        }

        .seller {
          color: #a36e29;
          margin-left: auto;
          font-weight: 600;
          font-size: 0.8rem;
          text-decoration: underline;
          cursor: pointer;
        }

        .icons {
          margin-left: auto;
          color: #a36e29;

          .MuiButtonBase-root {
            margin-left: 20px;
          }
        }

        .search {
          margin-left: 20px;
          width: 30vw;
          margin-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1.5px solid #a36e29;
          border-radius: 32px;

          .search-icon {
            padding: 10px 2px 5px 55px;
            color: #a36e29;
          }

          .input-root {
            color: inherit;
            width: 30vw;
          }

          .input-input {
            padding: 10px;
            color: #555555;
            font-family: "Roboto", sans-serif;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  .mobile {
    display: none;
  }

  @media (max-width: 900px) {
    .web {
      display: none;
    }

    .mobile {
      display: block;

      .appbar {
        height: 13vh;
        background-color: #fff;
        width: 100%;

        .rates {
          height: 2rem;
          width: 100%;
          background: linear-gradient(to left, #a36e29, #c18a3d);
        }

        .toolbar {
          position: relative;
          margin: auto;
          width: 90%;
          height: calc(13vh - 2rem);
          display: flex;
          align-items: center;
          border: none;
          box-shadow: none;

          .logo {
            height: 80px;
            margin-right: auto;
            vertical-align: middle;
            cursor: pointer;
          }

          .search {
            margin-left: auto;
            margin-right: 0px;
            display: flex;
            align-items: center;

            .search-icon {
              padding: 5px;
              color: #a36e29;
            }
          }
          .menu-icon {
            margin-left: 5px;
            color: #a36e29;
            opacity: 0.75;
          }
        }
      }
    }
  }
}
